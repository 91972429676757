import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const cookiesbarCompData = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        wp {
          mdaThemeOptions {
            themeSettings {
              cookies {
                # Header
                header
                desc

                # Buttons texts and links
                buttonText
                showDetailsText
                hideDetailsText
                necessaryCookiesText
                learnMoreText
                learnMoreLink {
                  target
                  url
                  title
                }

                # Cookies groups
                cookiesGroups {
                  header
                  desc
                  cookiesItems {
                    name
                    provider
                    purpose
                    expiry
                    type
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return data.wp.mdaThemeOptions.themeSettings.cookies;
};

export { cookiesbarCompData };
