// Default imports
import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Link from "../Link";

// Import SVG
import LogoMda from "../../images/mda.svg";

const FooterBottom = () => {
  // Make query
  const queryData = useStaticQuery(
    graphql`
      query {
        wpMenu(locations: { eq: FOOTER_BOTTOM }) {
          id
          locations
          menuItems {
            nodes {
              id
              parentId
              label
              path
              childItems {
                nodes {
                  id
                  path
                  label
                }
              }
            }
          }
        }
      }
    `
  );

  const menuItems = queryData.wpMenu.menuItems.nodes;

  return (
    <div className="footer-bottom">
      <ul className="footer-bottom__nav">
        <li className="footer-bottom__nav-item">
          <p className="footer-bottom__nav-text">
            © {new Date().getFullYear()} Wyden. All Right Reserved.
          </p>
        </li>

        {menuItems.map(({ id, label, path }) => (
          <li key={id} className="footer-bottom__nav-item">
            <Link to={path} className="footer-bottom__nav-link">
              {label}
            </Link>
          </li>
        ))}

        <li className="footer-bottom__nav-item">
          <a
            href="https://mda.pl/"
            target="_blank"
            className="footer-bottom__nav-link"
          >
            Developed by <LogoMda className="footer-bottom__authors-logo" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterBottom;
