// Default imports
import React, { useState, useLayoutEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import Link from "./Link";
import NavbarMenu from "./NavbarMenu";
import Burger from "./Burger";

// Import Bootstrap Components and styles
import { Container } from "react-bootstrap";
import ButtonText from "./ButtonText";

const NavbarComponent = ({ data, variant, isBannerOpen, hideButton }) => {
  const [scrolled, setScrolled] = useState(false);
  const [notScrolled, setNotScrolled] = useState(true);
  const [scrollDown, setScrollDown] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollDown = currentScrollPos - scrollPos > 0;
      const isScrolled = currentScrollPos > 200;
      const isNotScrolled = currentScrollPos < 25;

      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }

      if (isNotScrolled !== notScrolled) {
        setNotScrolled(isNotScrolled);
      }

      if (isScrollDown !== scrollDown) {
        setScrollDown(isScrollDown);
      }

      setScrollPos(currentScrollPos);
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled, scrollPos]);

  const toggleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const { navbarLogo: logo, button } = data.wp.mdaThemeOptions.themeSettings;

  return (
    <div
      className={`navbar ${variant === "white" ? "-white" : ""} ${
        variant === "transparent" && notScrolled ? "-transparent" : ""
      } ${scrolled && scrollDown ? "-hide" : ""} ${
        isMenuOpen ? "-is-menu-open" : ""
      } ${isBannerOpen ? "navbar__notice-banner" : ""}`}
    >
      <Container className="navbar__container">
        <Link className="navbar__logo-wrapper" to="/">
          <div
            className="navbar__logo"
            dangerouslySetInnerHTML={{
              __html: logo?.localFile.childSvg?.content.data,
            }}
          />
        </Link>
        <Burger toggleMenuOpen={toggleMenuOpen} isMenuOpen={isMenuOpen} />
        <NavbarMenu
          onClick={closeMenu}
          location="MAIN_MENU"
          isOpen={isMenuOpen}
        />
        {!hideButton && button && (
          <ButtonText
            className="navbar__buttons"
            onPointerDown={closeMenu}
            {...button.link}
            variant={variant === "white" ? "white,small" : "red-filled,small"}
          >
            {button.link.title}
          </ButtonText>
        )}
      </Container>
    </div>
  );
};

function Navbar(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
          wp {
            mdaThemeOptions {
              themeSettings {
                navbarLogo {
                  id
                  altText
                  localFile {
                    id
                    childImageSharp {
                      gatsbyImageData
                    }
                    childSvg {
                      content {
                        data
                      }
                    }
                  }
                }
                button {
                  variant
                  link {
                    url
                    title
                    target
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <NavbarComponent data={data} {...props} />}
    />
  );
}

export default Navbar;
