// Default imports
import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import FooterMenu from "../Footer/FooterMenu";

const FooterColumns = ({ className, wpMenuLocation = "FOOTER_1" }) => {
  // Make query
  const queryData = useStaticQuery(
    graphql`
      query {
        allWpMenu {
          nodes {
            id
            locations
            name
          }
        }
      }
    `
  );

  // Filter menu by location
  const menu = queryData.allWpMenu.nodes.find((menuItem) => {
    return menuItem.locations.includes(wpMenuLocation.toUpperCase());
  });

  const menu_bttm = queryData.allWpMenu.nodes.find((menuItem) => {
    return menuItem.locations.includes(`${wpMenuLocation.toUpperCase()}_2`);
  });

  return (
    <>
      <div className={`footer-columns__item-wrapper ${className ? className : ""}`} >
        <FooterColumnsItem wpMenuLocation={wpMenuLocation} menu={menu} />
      </div>

      {menu_bttm && (
        <div className={`footer-columns__item-wrapper ${className ? className : ""}`} >
          <FooterColumnsItem wpMenuLocation={`${wpMenuLocation}_2`} menu={menu_bttm} />
        </div>
      )}
      
    </>
  );
};

const FooterColumnsItem = ({ className, menu, wpMenuLocation }) => {
  // Get menu name
  const { name } = menu;

  return (
    <div className={`footer-columns__item ${className ? className : ""}`}>
      <h6 className="footer-columns__header">{name}</h6>
      <FooterMenu wpMenuLocation={wpMenuLocation} />
    </div>
  );
};

export default FooterColumns;
