import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

/**
 * Send MailChimp email using gatsby-plugin-mailchimp
 *
 * @param {string|Email} email Subscriber email
 * @param {array} listField Additional fields
 * @param {function} onSuccess Callback executed on success
 * @param {function} onError Callback executed on error
 *
 * @source https://www.gatsbyjs.com/plugins/gatsby-plugin-mailchimp/?=mailch
 */
const useMailchimpSender = async ({
  formUrl,
  email,
  listFields = [],
  onSuccess,
  onError,
}) => {
  addToMailchimp(email, listFields, formUrl) // listFields are optional if you are only capturing the email address.
    .then((data) => {
      if (data.result === "error") {
        onError([
          {
            fieldName: -1,
            message: data.msg,
          },
        ]);
      } else {
        onSuccess();
      }
    });
};

export default useMailchimpSender;
