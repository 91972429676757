// Default imports
import React from "react";
import Link from "./Link";

// Button Text
const ButtonText = ({
  children,
  className,
  url,
  title,
  target,
  variant,
  onClick,
  ...args
}) => {
  const isRegularLink = /mailto:|\.[a-z]{2,4}$/gi.test(url);
  const variantClassess = variant
    ? variant
        .split(",")
        .map((item) => `-${item}`)
        .join(" ")
    : "";

  if (onClick)
    return (
      <button
        className={`button-text ${
          className ? className : ""
        } ${variantClassess}`}
        onClick={onClick}
        href="#"
      >
        {children}
      </button>
    );

  if (isRegularLink)
    return (
      <a
        className={`button-text ${
          className ? className : ""
        }  -regular-link ${variantClassess}`}
        href={url}
        title={title}
        target={target}
        {...args}
      >
        {children}
      </a>
    );

  return (
    <Link
      className={`button-text ${className ? className : ""} ${variantClassess}`}
      to={url}
      title={title}
      target={target}
      {...args}
    >
      {children}
    </Link>
  );
};

export default ButtonText;
