import * as React from "react";

const DangerousInnerHtmlLoader = ({ htmlContent, id }) => {
    return (
        <div
            id={id}
            className="danegours-inner-html-loader -d-none"
            dangerouslySetInnerHTML={{
                __html: htmlContent,
            }}
        ></div>
    );
};

export default DangerousInnerHtmlLoader;
