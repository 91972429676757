// Default imports
import React from "react";
import { Row, Col } from "react-bootstrap";

import WpImage from "../../components/WpImage";

// Import GraphQl data
import { footerTopData } from "../../data/options-footer-top.data";

// Import SVG
import Logo from "../../images/white-logo-cropped.svg";

const FooterTop = () => {
  const { socialMedia: socials } = footerTopData();
  return (
    <div className="footer__top">
      <Row className="align-items-center">
        <Col className="col-12" md="7" lg="6" xl="4">
          <Logo className="footer__logo" />
        </Col>
        <Col
          className="col-12"
          md={{ span: 16, offset: 1 }}
          lg={{ span: 17, offset: 1 }}
          xl={{ span: 16, offset: 2 }}
        >
          <div className="footer-social">
            {socials.map(({ link, icon }) => (
              <a
                key={link.url}
                href={link.url}
                title={link?.title}
                target={link.target}
                className="footer-social__item"
              >
                <WpImage className="footer-social__icon" image={icon} />
              </a>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FooterTop;
