// Default imports
import React, { useState, useRef } from "react";

import ContainerWide from "../components/ContainerWide";
import FormGroup from "../components/Form/FormGroup";

import { getNewsletterCompData } from "../data/components-newsletter.data";
import useMailchimpSender from "../hooks/useMailchimpSender";

const Newsletter = ({ fullWidth }) => {
    const compData = getNewsletterCompData();
    const {
        buttonText,
        header,
        inputPlaceholder,
        headerSuccess,
        headerSending,
        mailchimpFormUrl,
    } = compData.wp.mdaThemeOptions.themeSettings.newsletter;

    // Form fields
    const [formFields, setFormFields] = useState([
        {
            tag: "input",
            name: "email",
            type: "email",
            placeholder: inputPlaceholder,
            required: true,
        },
    ]);

    // States
    const [success, setSuccess] = useState(false);
    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState([]);

    // Refs
    const newsletterForm = useRef();

    // Handlers
    const handleChange = (e) => {
        const name = e.target.getAttribute("name");

        const formFieldsChanged = formFields.map((field) => {
            return { ...field, value: e.target.value };
        });
        setFormFields(formFieldsChanged);
        setErrors(errors.filter((error) => error.fieldName !== name));
        setErrors([]);
    };

    const handleSuccess = () => {
        setSending(false);
        setSuccess(true);
    };

    const handleError = (err) => {
        setSending(false);
        setErrors(err);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setSending(true);
        setErrors([]);

        useMailchimpSender({
            formUrl: mailchimpFormUrl,
            email: formFields[0].value,
            onSuccess: handleSuccess,
            onError: handleError,
        });
    };

    return (
        <ContainerWide
            className={`newsletter ${fullWidth ? "-full-width" : ""}`}
        >
            <div className="newsletter__wrapper">
                <p className="newsletter__title">
                    {success ? headerSuccess : sending ? headerSending : header}
                </p>
                <form
                    ref={newsletterForm}
                    className="newsletter-form"
                    onSubmit={handleSubmit}
                >
                    {/* Programmicly create fields from fields array */}
                    {formFields.map((formField) => (
                        <FormGroup
                            fieldClassName={`newsletter-form__${formField.tag}`}
                            key={formField.name}
                            disabled={success}
                            errors={errors}
                            onChange={handleChange}
                            {...formField}
                        />
                    ))}

                    <button
                        type="submit"
                        className="newsletter-form__submit"
                        disabled={success || errors.length > 0}
                    >
                        {buttonText}
                    </button>

                    <div className="newsletter-form__errors">
                        {errors
                            .filter((error) => error.fieldName === -1)
                            .map((error, index) => (
                                <p
                                    key={index}
                                    className="newsletter-form__error"
                                    dangerouslySetInnerHTML={{
                                        __html: error.message,
                                    }}
                                />
                            ))}
                    </div>
                </form>
            </div>
        </ContainerWide>
    );
};

export default Newsletter;
