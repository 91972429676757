import React from "react";
import ReactSelect from "react-select";

const Select = ({ ...props }) => {
  return (
    <ReactSelect
      classNamePrefix="select"
      styles={selectStyles}
      isSearchable
      {...props}
    />
  );
};

const selectStyles = {
  control: (provided, state) => ({}),
  placeholder: (provided, state) => ({}),
  input: () => ({}),
};

export default Select;
