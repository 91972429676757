import React from "react";
import parse from "html-react-parser";
import { SVGUniqueID } from "react-svg-unique-id";

/**
 * Creates DOM element from string with SVG
 * @param {String} svg
 * @returns {DOMElement} An SVG dom node
 */
function FixSvg({ svg, ...attrs }) {
  if (svg) {
    const svgParsed = parse(svg, {
      replace: (node) => {
        if (node.name === "svg") {
          node.attribs.preserveAspectRatio = "xMidYMin meet";
          node.attribs = {
            ...node.attribs,
            ...attrs,
          };
        }
      },
    });

    return <SVGUniqueID>{svgParsed}</SVGUniqueID>;
  } else {
    return <></>;
  }
}

export { FixSvg };
