import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const getNewsletterCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        # Section text
        wp {
          mdaThemeOptions {
            themeSettings {
              newsletter {
                header
                headerSending
                headerSuccess
                inputPlaceholder
                buttonText
                mailchimpFormUrl
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export { getNewsletterCompData };
