import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const footerTopData = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          mdaThemeOptions {
            themeSettings {
              socialMedia {
                link {
                  url
                  title
                  target
                }
                icon {
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                    childSvg {
                      content {
                        data
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return data.wp.mdaThemeOptions.themeSettings;
};

export { footerTopData };
