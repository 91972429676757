// Default imports
import React from "react";
import { Col, Row } from "react-bootstrap";

import IconChevronBottom from "../icons/chevron-bottom-16.svg";
import Arrow24 from "../images/arrow-24.svg";
import WpImage from "../components/WpImage";
import ButtonText from "../components/ButtonText";
import Link from "./Link";

const ListSpecial = ({ listItem }) => (
  <div className="list-special">
    {listItem.map(
      ({ header, images, imageShadowDisable, content, alignItems }, index) => {
        return (
          <Row
            key={index}
            className={`list-special__row ${
              alignItems ? `align-items-${alignItems}` : ""
            }`}
          >
            <Col
              xs="24"
              lg={index % 2 === 0 ? { span: 14, offset: 2 } : { span: 14 }}
              className={`${
                index % 2 !== 0 ? "order-lg-1" : "order-lg-2"
              } list-special__images`}
            >
              {images.map(({ image, imageShadowDisable }) => {
                return (
                  <WpImage
                    key={index}
                    image={image}
                    className={`list-special__image ${
                      imageShadowDisable ? "-shadow-disable" : ""
                    }`}
                  />
                );
              })}
            </Col>
            <Col
              xs="24"
              lg={index % 2 === 0 ? { span: 8 } : { span: 8, offset: 2 }}
              className={index % 2 === 0 ? "order-lg-1" : "order-lg-2"}
            >
              <h3 className="list-special__title">{header}</h3>

              {content.map((contentItem, index) => (
                <ListSpecialContentItem key={index} {...contentItem} />
              ))}
            </Col>
          </Row>
        );
      }
    )}
  </div>
);

const ListSpecialContentItem = ({ fieldGroupName, ...itemData }) => {
  switch (true) {
    case /Content_Text$/.test(fieldGroupName):
      return <ListSpecialContentItemText {...itemData} />;

    case /Content_Link$/.test(fieldGroupName):
      return <ListSpecialContentItemLink {...itemData} />;

    case /Content_List$/.test(fieldGroupName):
      return <ListSpecialContentItemList {...itemData} />;

    case /Content_AccordionLink$/.test(fieldGroupName):
      return <ListSpecialContentItemAccordionLink {...itemData} />;

    case /Content_Accordion$/.test(fieldGroupName):
      return <ListSpecialContentItemTextFoldable {...itemData} />;

    default:
      return <></>;
  }
};

const ListSpecialContentItemText = ({ header, text, link, slim }) => (
  <div className={`list-special__item ${slim ? "-slim" : ""}`}>
    <h4 className="list-special__header">{header}</h4>
    <div className="list-special__text">{text}</div>
    {link && (
      <ButtonText className="list-special__link" variant="red,slim" {...link}>
        {link?.title}
      </ButtonText>
    )}
  </div>
);

const ListSpecialContentItemLink = ({ type, link, file, nested }) => {
  return (
    <Link
      type={type}
      link={link}
      file={file}
      className={`list-special__link-item ${nested ? "-nested" : ""}`}
    />
  );
};

const ListSpecialContentItemAccordionLink = ({
  type,
  link,
  file,
  locked,
  linkClassName,
  className,
}) => (
  <div
    className={`list-special__item -accordion -accordion__link ${
      className || ""
    }`}
  >
    <Link
      type={type}
      link={link}
      file={file}
      className={`list-special__header ${locked ? "-locked" : ""} ${
        linkClassName || ""
      }`}
    />
  </div>
);

const ListSpecialContentItemTextFoldable = ({
  header,
  content,
  onClick,
  folded,
}) => (
  <div className={`list-special__item -accordion ${folded ? "-folded" : ""}`}>
    <h4 onClick={onClick} className="list-special__header">
      {header}
      <IconChevronBottom className="list-special__chevron" />
    </h4>
    <div className="list-special__content">
      {content?.map((item, i) => {
        switch (true) {
          case /Content_Text$/.test(item.fieldGroupName):
            return <ListSpecialContentItemText key={i} slim {...item} />;

          case /Content_List$/.test(item.fieldGroupName):
            return <ListSpecialContentItemList key={i} slim {...item} />;

          case /Content_Link$/.test(item.fieldGroupName):
            return <ListSpecialContentItemLink key={i} slim {...item} />;

          case /Content_AccordionLink$/.test(item.fieldGroupName):
            return (
              <ListSpecialContentItemAccordionLink key={i} slim {...item} />
            );

          default:
            return <></>;
        }
      })}
    </div>
  </div>
);

const ListSpecialContentItemList = ({
  header,
  items,
  textAbove,
  textBelow,
  slim,
}) => (
  <div className={`list-special__item ${slim ? "-slim" : ""}`}>
    <h5 className="list-special__header">{header}</h5>

    {textAbove && <p className="list-special__text">{textAbove}</p>}

    {items && (
      <ul className="list-special__list">
        {items.map(({ text }, index) => (
          <li key={index} className="list-special__list-item" dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </ul>
    )}

    {textBelow && <p className="list-special__text">{textBelow}</p>}
  </div>
);

export default ListSpecial;
export { ListSpecialContentItem };
