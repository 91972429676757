import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const footerMainData = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          mdaThemeOptions {
            themeSettings {
              footerContactHeader
              footerContactItems {
                email
                location
                phone
                title
              }
            }
          }
        }
      }
    `
  );

  return data.wp.mdaThemeOptions.themeSettings;
};

export { footerMainData };
