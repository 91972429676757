import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

// Import templates
import NavbarMenuItem from "./NavbarMenuItem";
import { ListSpecialContentItem } from "../ListSpecial";
import { useIsMobile } from "../../hooks/useIsMobile";

const DesktopMenu = ({ menuItems }) => {
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div
      className="navbar-menu__wrapper"
      onMouseLeave={() => setActiveItem(null)}
    >
      <ul className="navbar-menu__items">
        {menuItems
          .filter((menuItem) => menuItem.parentId === null)
          .map((menuItem, i) => {
            return (
              <NavbarMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                isActive={i === activeItem}
                onClick={() => setActiveItem(i)}
              />
            );
          })}
      </ul>
    </div>
  );
};

const MobileMenu = ({ menuItems }) => {
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className="navbar-menu__mobile-wrapper">
      <section className="navbar-menu__mobile-items">
        {menuItems
          .filter((menuItem) => menuItem.parentId === null)
          .map((parentMenuItem, i) =>
            parentMenuItem.childItems?.nodes?.length > 0 ? (
              <ListSpecialContentItem
                key={parentMenuItem.id}
                fieldGroupName="Content_Accordion"
                header={parentMenuItem.label}
                onClick={() =>
                  setActiveItem((activeItem) => (activeItem === i ? null : i))
                }
                folded={i !== activeItem}
                content={parentMenuItem.childItems.nodes.map(
                  (childMenuItem) => ({
                    fieldGroupName: "Content_AccordionLink",
                    className: "-nested",
                    header: childMenuItem.label,
                    link: {
                      title: childMenuItem.label,
                      url: childMenuItem.path,
                    },
                  })
                )}
              />
            ) : (
              <ListSpecialContentItem
                key={parentMenuItem.id}
                fieldGroupName="Content_AccordionLink"
                linkClassName="px-0"
                header={parentMenuItem.label}
                link={{
                  title: parentMenuItem.label,
                  url: parentMenuItem.path,
                }}
              />
            )
          )}
      </section>
    </div>
  );
};

const NavbarMenu = ({ wpMenuLocation = "MAIN_MENU", isOpen }) => {
  const queryData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allWpMenu {
          edges {
            node {
              id
              locations
              menuItems {
                nodes {
                  id
                  parentId
                  label
                  path
                  menuRichContent {
                    description
                    numberOfColumns
                  }
                  childItems {
                    nodes {
                      id
                      path
                      label
                      menuRichContent {
                        emphasis
                        icon {
                          ...wpImage
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const isMobile = useIsMobile();

  // Filter menu by location
  const menu = queryData.allWpMenu.edges.find((menuItem) => {
    return menuItem.node.locations.includes(wpMenuLocation.toUpperCase());
  });

  // Return if no menu has been found in this location
  if (!menu) {
    console.warn("No menu with this location has been found");
    return;
  }

  return (
    <nav className={`navbar-menu ${isOpen && isMobile ? "-is-open" : ""} `}>
      {isMobile ? (
        <MobileMenu menuItems={menu.node.menuItems.nodes} />
      ) : (
        <DesktopMenu menuItems={menu.node.menuItems.nodes} />
      )}
    </nav>
  );
};

export default NavbarMenu;
