// Default imports
import React from "react";
import { Container } from "react-bootstrap";

const ContainerWide = ({ children, className, ...params }) => (
  <div className={`container-wide ${className ? className : ""}`} {...params}>
    {children}
  </div>
);

export default ContainerWide;
