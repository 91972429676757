// Default imports
import React, { useState } from "react";

const Burger = ({ isMenuOpen, toggleMenuOpen }) => {
  return (
    <button
      type="button"
      onClick={toggleMenuOpen}
      className={`burger-icon ${isMenuOpen ? "is-open" : ""}`}
    >
      <span className="burger-icon__bar"></span>
      <span className="burger-icon__bar"></span>
      <span className="burger-icon__bar"></span>
      <span className="burger-icon__bar"></span>
    </button>
  );
};

export default Burger;
