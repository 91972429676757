// Default imports
import React from "react";
import Select from "./Select";

const FormGroup = ({
  // Classess
  className,
  fieldClassName,
  labelClassName,

  // Data
  optionalText,
  errors,
  label,
  tag,
  name,
  type,
  options,
  required,
  disabled,

  //Handlers
  ...props
}) => {
  const fieldErrors = errors.filter((error) => error.fieldName === name);

  return (
    tag && (
      <div
        className={`form-group ${className ? className : ""} ${
          disabled ? "-disabled" : ""
        } ${type === "hidden" ? "d-none" : ""}`}
      >
        {label && (
          <label
            htmlFor={name}
            className={`form-group__label ${
              labelClassName ? labelClassName : ""
            }`}
          >
            <span dangerouslySetInnerHTML={{ __html: label }} />
            {required && <span>*</span>}
          </label>
        )}
        <FormField
          tag={tag}
          name={name}
          type={type}
          required={required}
          disabled={disabled}
          options={options ? options : ""}
          className={`${fieldErrors.length > 0 ? "-error" : ""} ${
            fieldClassName ? fieldClassName : ""
          }`}
          {...props}
        />

        {fieldErrors.some(({ message }) => message && message !== "") && (
          <div className="form-group__errors">
            {fieldErrors.map(
              (error) =>
                error.message &&
                error.message.length > 0 && (
                  <p key={error.error_id} className="form-group__error">
                    {error.message}
                  </p>
                )
            )}
          </div>
        )}
      </div>
    )
  );
};

const FormField = ({
  name,
  value,
  tag,
  options,
  disabled,
  onChange,
  ...props
}) => {
  return tag === "input" || tag === "textarea" ? (
    React.createElement(tag, {
      ...props,
      name: name,
      value: value || "",
      disabled: disabled,
      onChange: onChange,
    })
  ) : (
    <Select
      name={name}
      options={options.filter(
        (option) => option.value !== null && option.label !== null
      )}
      onChange={(e) => {
        onChange({ type: "select", name: name, value: e.value });
      }}
      isDisabled={disabled}
      isSearchable
      {...props}
    />
  );
};

export default FormGroup;
