// Default imports
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

// Import components
import FooterBottom from "./FooterBottom";
import FooterColumns from "./FooterColumns";

// Import GraphQl data
import { footerMainData } from "../../data/options-footer-main.data";

// Import SVG icons
import LocationIcon from "../../images/location.svg";
import PhoneIcon from "../../images/phone.svg";
import MailIcon from "../../images/mail.svg";
import IconChevronBottom from "../../icons/chevron-bottom-16.svg";

const FooterMain = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const {
    footerContactHeader: header,
    footerContactItems: items,
  } = footerMainData();

  const menuLength = 6;

  return (
    <div>
      <Row>
        <Col className="col-24 col-rg-7" lg={6} xl={4}>
          <div className="footer-columns -infos">
            <h6 className="footer-columns__header">{header}</h6>
            <div className="footer-locations">
              {items.map((item, index) => (
                <FooterLocation
                  key={item.title}
                  index={index}
                  activeIndex={activeIndex}
                  onClick={handleItemClick}
                  {...item}
                />
              ))}
            </div>
          </div>
        </Col>
        <Col
          className="col-24 col-rg-16 offset-rg-1 d-flex flex-column justify-content-between"
          rg={{ span: 18 }}
          lg={{ span: 17, offset: 1 }}
          xl={{ span: 16, offset: 2 }}
        >
          <div className="footer-columns">
            {[...Array(menuLength)].map((el, index) => (
              <FooterColumns
                key={`footer-columns-${index}`}
                wpMenuLocation={`FOOTER_${index + 1}`}
              />
            ))}
          </div>

          <div className="d-none d-xl-block">
            <FooterBottom />
          </div>
        </Col>
        <Col lg="24" className="d-block d-xl-none">
          <FooterBottom />
        </Col>
      </Row>
    </div>
  );
};

const FooterLocation = ({
  title,
  location,
  phone,
  email,
  index,
  activeIndex,
  onClick,
}) => (
  <div
    className={`footer-locations__item ${
      index === activeIndex ? "is-open" : ""
    }`}
  >
    <div className="footer-locations__item-header"
    onClick={() => {
      onClick(index);
    }}>
      {title}
      <IconChevronBottom className="footer-locations__item-icon" />
    </div>

    <div className="footer-locations__item-content">
      <p className="footer-locations__item-text">
        <LocationIcon className="footer-locations__item-text__icon" />
        <span dangerouslySetInnerHTML={{ __html: location }} />
      </p>
      <p className="footer-locations__item-text">
        <PhoneIcon className="footer-locations__item-text__icon" />
        {phone}
      </p>
      <p className="footer-locations__item-text">
        <MailIcon className="footer-locations__item-text__icon" />
        {email}
      </p>
    </div>
  </div>
);

export default FooterMain;
