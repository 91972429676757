// Default imports
import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Link from "../Link";

const FooterMenu = ({ wpMenuLocation = "FOOTER_1" }) => {
  // Make query
  const queryData = useStaticQuery(
    graphql`
      query {
        allWpMenu {
          nodes {
            id
            locations
            menuItems {
              nodes {
                id
                parentId
                label
                path
                childItems {
                  nodes {
                    id
                    path
                    label
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // Filter menu by location
  const menu = queryData.allWpMenu.nodes.find((menuItem) => {
    return menuItem.locations.includes(wpMenuLocation.toUpperCase());
  });

  if (!menu) {
    return <></>;
  }

  // Get menu name
  const { menuItems } = menu;

  return (
    <ul className="footer-menu">
      {menuItems?.nodes &&
        menuItems.nodes.map((menuItem) => {
          const { id, path, label } = menuItem;
          return (
            <li key={id} className="footer-menu__item">
              <Link
                to={path}
                className="footer-menu__link"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            </li>
          );
        })}
    </ul>
  );
};

export default FooterMenu;
