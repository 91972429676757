import React, { useState, useEffect } from "react";
import Link from "../Link";
import WpImage from "../WpImage";
import { Popover, ArrowContainer } from "react-tiny-popover";

const NavbarMenuItemDropdown = ({ menuItem, onClick, isActive }) => {
  const [hover, setHover] = useState(false);

  return (
    <Popover
      isOpen={isActive || hover}
      positions={["bottom"]}
      align="center"
      padding={1}
      containerStyle={{
        zIndex: 1000000,
      }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"rgba(255, 255, 255, 0.93)"}
          arrowSize={12}
          arrowStyle={{
            zIndex: 1000,
          }}
        >
          <div
            className="navbar-menu__dropdown"
            onMouseEnter={() => {
              setHover(true);
              // setShow(true);
              // onClick();
            }}
            onMouseLeave={(e) => {
              setHover(false);
              // setShow(false);
            }}
          >
            {menuItem.menuRichContent.description && (
              <div
                className="navbar-menu__description"
                style={{
                  gridColumn: `span ${menuItem.menuRichContent.numberOfColumns}`,
                }}
              >
                {menuItem.menuRichContent.description}
              </div>
            )}
            {menuItem.childItems?.nodes.map((menuItemChild) => (
              <NavbarMenuItem
                key={menuItemChild.id}
                inDropdown={true}
                menuItem={menuItemChild}
                onClick={onClick}
              />
            ))}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        key={menuItem.id}
        role="menuitem"
        tabIndex="0"
        className={`navbar-menu__item -dropdown`}
        onMouseEnter={() => {
          // setShow(true);
          onClick();
        }}
        // onMouseLeave={() => {
        //   setHover((hover) => ({ ...hover, link: false }));
        // }}
      >
        <Link key={menuItem.id} className={`navbar-menu__link`}>
          {menuItem.label}
        </Link>
      </div>
    </Popover>
  );
};

const NavbarMenuItemDefault = ({ menuItem, inDropdown }) => {
  return (
    <div
      key={menuItem.id}
      role="menuitem"
      tabIndex="0"
      className={`navbar-menu__item ${inDropdown ? "-nested" : ""} ${
        menuItem.menuRichContent.emphasis ? "-emphasis" : ""
      }`}
    >
      <Link className={`navbar-menu__link`} to={menuItem.path}>
        {menuItem.menuRichContent.icon && (
          <WpImage
            image={menuItem.menuRichContent.icon}
            className="navbar-menu__item-icon"
            style={{ pointerEvents: "none" }}
          />
        )}
        <span>{menuItem.label}</span>
      </Link>
    </div>
  );
};

const NavbarMenuItem = ({ menuItem, inDropdown, isActive, onClick }) => {
  if (menuItem.childItems?.nodes.length > 0) {
    return (
      <NavbarMenuItemDropdown
        key={menuItem.id}
        menuItem={menuItem}
        onClick={onClick}
        isActive={isActive}
      />
    );
  } else {
    return (
      <NavbarMenuItemDefault
        key={menuItem.id}
        menuItem={menuItem}
        inDropdown={inDropdown}
      />
    );
  }
};

export default NavbarMenuItem;
