import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FixSvg } from "../helpers/svgHelpers";

const WpImage = ({ image, ...attrs }) => {
  return image ? (
    image.localFile.extension === "svg" ? (
      <FixSvg svg={image.localFile.childSvg?.content.data} {...attrs} />
    ) : (
      <GatsbyImage
        image={getImage(image.localFile.childImageSharp?.gatsbyImageData)}
        alt={image.altText || ""}
        {...attrs}
      />
    )
  ) : (
    <></>
  );
};
export default WpImage;
