// Default imports
import React from "react";

import ContainerWide from "../components/ContainerWide";

import FooterTop from "../components/Footer/FooterTop";
import FooterMain from "../components/Footer/FooterMain";

const Footer = ({ fullWidth }) => (
    <ContainerWide className={`${fullWidth ? "-full-width" : ""}`}>
        <footer className="footer">
            <FooterTop />
            <FooterMain />
        </footer>
    </ContainerWide>
);

export default Footer;
