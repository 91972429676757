import * as React from "react";
import { createPortal } from "react-dom";

const portalRoot =
    typeof document !== `undefined` ? document.getElementById("portal") : null;

const BodyPortal = ({ children }) => {
    if (portalRoot === null || portalRoot === undefined) return null;

    return createPortal(children, portalRoot);
};

export default BodyPortal;
